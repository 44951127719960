import React, { useState } from "react"
import "../../../styles/InfluencerFooter.css"
import { ImArrowRight2 } from "react-icons/im"
// import { FaFacebookF, FaTwitter } from "react-icons/fa"
// import { GrInstagram } from "react-icons/gr"
import { Link } from "gatsby"
import { openPopupWidget } from "react-calendly"
import footerLogo from '../../../../static/assets/footerLogo.png'
import useSiteMetadata from "../../SiteMetaData";

function InfluencerFooter() {
  const {calendlyDemoUrl} = useSiteMetadata()
  const [userData, setUserData] = useState({ email: "", instaUsername: "" })
  const onHandleChange = e => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const bookDemo = () => openPopupWidget({ url: calendlyDemoUrl })

  const formSubmit = e => {
    e.preventDefault()
    const prefill = {
      email: userData.email,
      customAnswers: {
        a1: userData.instaUsername,
      },
    }
    openPopupWidget({ url: calendlyDemoUrl, prefill })
    setUserData({ ...userData, email: "", instaUsername: "" })
  }
  
  return (
    <div>
      <div className="container influencerFooterContainer is-fluid">
        <div className="columns footerColumns  is-multiline is-mobile ">
          <div className="column is-3-desktop is-4-mobile">
            <div className="footerLogoContainer">
              <img src={footerLogo} loading="lazy" alt="footerLogo" title="footerLogo" className="image" />
            </div>

            <p className=" footerInputText">Let your existing customers, get you more customers by posting on Instagram</p>
            <div className="footerInputBox">
              <div className="mt-5">
                <form onSubmit={formSubmit}>
                  <input
                    className="input has-text-white is-size-6 is-size-7-mobile footerInput py-5"
                    type="email"
                    placeholder="Your Email"
                    name="email"
                    onChange={onHandleChange}
                    required
                    autoComplete="off"
                  />
                  <div className="mt-4 has-icons-right control instaContainer">
                    <input
                      className="input has-text-white is-size-6 is-size-7-mobile footerInput footerInputInsta py-5"
                      type="text"
                      placeholder="Instagram Username(optional)"
                      name="instaUsername"
                      onChange={onHandleChange}
                      required
                      autoComplete="off"
                    />
                    <button
                      className="button is-medium  footerArrowContainer"
                    >
                      <ImArrowRight2 className="footerArrowIcon has-text-white" />
                    </button>
                  </div>
                 
                </form>
              </div>
            </div>
            <p className="mt-5 footerInputText" onClick={() => bookDemo()} onKeyDown={()=>bookDemo()} role="presentation">
              Book a Demo Now!
            </p>
          </div>
          {/* <div className="column is-2-desktop is-offset-1-desktop is-hidden-mobile">
            <div className="footerIconsContainer mt-3 ">
              <FaFacebookF className="has-text-white footerIcons  is-size-5" />
              <FaTwitter className="has-text-white footerIcons footerIcon is-size-5 " />
              <GrInstagram className="has-text-white footerIcons footerIcon is-size-5 " />
            </div>
          </div> */}
          <div className="column is-2-desktop has-text-left is-offset-2-desktop is-3-mobile footerDemoTextContainer">
            <span className="has-text-left mt-3 footerDemoText footerDemoTextHeader">
              Pages
            </span>
            <Link
                className="has-text-left has-text-white footerDemoText footerDemoSubText"
                to="/pricing"
            >
              Pricing
            </Link>
            <Link
                className="has-text-left has-text-white footerDemoText footerDemoSubText"
                href={"https://influencerbit.com/blogs"}
                target="_blank"
            >
              Blogs
            </Link>
            <Link
                className="has-text-left has-text-white footerDemoText footerDemoSubText"
                href={"https://help.influencerbit.com/"}
                target="_blank"
            >
              Guide / Help Center
            </Link>
            <Link
                className="has-text-left has-text-white footerDemoText footerDemoSubText"
                href={"https://terms.influencerbit.com/"}
                target={"_blank"}
            >
              Terms of Use
            </Link>
            <Link
                className="has-text-left has-text-white footerDemoText footerDemoSubText"
                href={"https://privacy.influencerbit.com/"}
                target="_blank">
              Privacy Policy
            </Link>
            <Link  className="has-text-left has-text-white footerDemoText footerDemoSubText" to="/integrations">
              Integrations
            </Link>
            <Link
                className="has-text-left has-text-white footerDemoText footerDemoSubText"
                to={"/demo_video"}
                target={"_blank"}
            >
              Demo Video
            </Link>
            {/* <div className="is-hidden-tablet">
              <div className="footerIconsContainer mt-4 ">
                <FaFacebookF className="has-text-white footerIcons  is-size-5" />
                <FaTwitter className="has-text-white footerIcons footerIcon is-size-5 " />
                <GrInstagram className="has-text-white footerIcons footerIcon is-size-5 " />
              </div>
            </div> */}
          </div>
          <div className="column is-2-desktop is-3-mobile  footerDemoTextContainer">
            <span className="has-text-left mt-3 footerDemoText footerDemoTextHeader">
              About us
            </span>
            <Link
                to="/whyInfluencerBit"
                className="has-text-left has-text-white footerDemoText footerDemoSubText"
            >
              Why InfluencerBit?
            </Link>
            <Link
                to="/whoAreWe"
                className="has-text-left has-text-white  footerDemoText footerDemoSubText"
            >
              Who are we?
            </Link>
          </div>
          <div className="column is-2-desktop is-2-mobile is-offset-1-desktop  footerDemoTextContainer">
            <span className="has-text-left mt-3 footerDemoText footerDemoTextHeader">
              Compare
            </span>
            <Link
                className="has-text-left has-text-white  footerDemoText footerDemoSubText"
                to={"/"}
            >
              Gatsby.ai vs InfluencerBit
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfluencerFooter
